import { render, staticRenderFns } from "./AnchorCreate.vue?vue&type=template&id=0b0c2b78&scoped=true&"
import script from "./AnchorCreate.vue?vue&type=script&lang=js&"
export * from "./AnchorCreate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b0c2b78",
  null
  
)

export default component.exports