<template>
    <div id="anchor-create" width="50%">

        <el-descriptions class="margin-top" title="基本信息" :column="2" border style="width: fit-content;">



            <el-descriptions-item label="姓名">
                <el-input v-model="createForm.name"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label="年龄">
                <el-input v-model="createForm.age" type="number"></el-input>
            </el-descriptions-item>


            <el-descriptions-item label="微信号">
                <el-input v-model="createForm.wechat"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label="抖音号">
                <el-input v-model="createForm.dy"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label="性别">
                <el-radio-group v-model="createForm.sex">
                    <el-radio label="MALE">男</el-radio>
                    <el-radio label="FEMALE">女</el-radio>
                </el-radio-group>
            </el-descriptions-item>
            <el-descriptions-item label="备注">
                <el-input v-model="createForm.remark"></el-input>
            </el-descriptions-item>
        </el-descriptions>

        <el-divider></el-divider>

        <el-descriptions class="margin-top" title="补充信息" :column="1" border style="width: fit-content;">

            <el-descriptions-item label="有无才艺">
                <el-radio-group v-model="createForm.talent">
                    <el-radio :label="true">有</el-radio>
                    <el-radio :label="false">无</el-radio>
                </el-radio-group>
            </el-descriptions-item>

            <el-descriptions-item label="才艺类型">
                <el-select v-if="createForm.talent" v-model="createForm.talentType" placeholder="请选择">
                    <el-option v-for="talentType in createInfo.talentTypes" :key="talentType" :value="talentType"
                        :label="createInfo.talentTypeDesc[talentType]">
                    </el-option>
                </el-select>
            </el-descriptions-item>

            <el-descriptions-item label="入职类型">
                <el-radio-group v-model="createForm.jobType">
                    <el-radio label="FULL_TIME">全职</el-radio>
                    <el-radio label="PART_TIME">兼职</el-radio>
                </el-radio-group>
            </el-descriptions-item>
            <el-descriptions-item label="资薪类型">
                <el-select v-model="createForm.salaryType" placeholder="请选择">
                    <el-option v-for="salaryType in createInfo.salaryTypes" :key="salaryType" :value="salaryType"
                        :label="createInfo.salaryTypeDesc[salaryType]">
                    </el-option>
                </el-select>
            </el-descriptions-item>
            <el-descriptions-item label="每天可接时间">
                <el-checkbox-group v-model="createForm.workTimeArr">
                    <el-checkbox v-for="item in this.workTimeList" :label="item" :key="item"></el-checkbox>
                </el-checkbox-group>
            </el-descriptions-item>
            <el-descriptions-item label="意愿就职日期">
                <el-date-picker v-model="createForm.expectStartDate" type="date" placeholder="选择日期"
                    value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-descriptions-item>
            <el-descriptions-item label="是否可以接超过2个月">
                <el-radio-group v-model="createForm.takeLongTime">
                    <el-radio :label="true">能</el-radio>
                    <el-radio :label="false">不能</el-radio>
                </el-radio-group>
            </el-descriptions-item>

            <el-descriptions-item label="意愿直播间类型">
                <el-select v-model="createForm.expectStudioType" placeholder="请选择">
                    <el-option v-for="studioType in createInfo.studioTypes" :key="studioType" :value="studioType"
                        :label="createInfo.studioTypeDesc[studioType]">
                    </el-option>
                </el-select>
            </el-descriptions-item>

            <el-descriptions-item label="声卡类型">
                <el-select v-model="createForm.scType" placeholder="无" clearable>
                    <el-option v-for="scType in createInfo.scTypes" :key="scType" :value="scType"
                        :label="createInfo.scTypeDesc[scType]">
                    </el-option>
                </el-select>
            </el-descriptions-item>

            <el-descriptions-item label="是否可以进工会">
                <el-radio-group v-model="createForm.canJoinGuild">
                    <el-radio :label="true">可以</el-radio>
                    <el-radio :label="false">不可以</el-radio>
                </el-radio-group>
            </el-descriptions-item>

            <el-descriptions-item label="是否有经验">
                <el-radio-group v-model="createForm.haveExp">
                    <el-radio :label="true">有</el-radio>
                    <el-radio :label="false">无</el-radio>
                </el-radio-group>
            </el-descriptions-item>
            <el-descriptions-item label="是否学生">
                <el-radio-group v-model="createForm.student">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                </el-radio-group>
            </el-descriptions-item>

            <el-descriptions-item label="经纪人">
                <el-select v-model="createForm.agentId">
                    <el-option v-for="agent in createInfo.agentList" :key="agent.id" :value="agent.id"
                        :label="'[' + agent.id + ']' + agent.nickName"></el-option>
                </el-select>
            </el-descriptions-item>

            <el-descriptions-item label="直播间">
                <el-select v-model="createForm.studioId">
                    <el-option v-for="studio in createInfo.studioList" :key="studio.id" :value="studio.id"
                        :label="'[' + studio.id + ']' + studio.name"></el-option>
                </el-select>
            </el-descriptions-item>

        </el-descriptions>
        <el-divider></el-divider>
        <el-button type="primary" @click="create()">保存</el-button>

    </div>
</template>
<script>
export default {
    name: "AUList",
    data() {
        return {

            workTimeList: ['6-8', '8-10', '10-12', '12-14', '14-16', '16-18', '18-20', '20-22', '22-24', '0-2', '2-4', '4-6'],

            createInfo: {
                sexes: null,
                talentTypes: null,
                talentTypeDesc: null,
                studioTypes: null,
                studioTypeDesc: null,
                scTypes: null,
                scTypeDesc: null,
                salaryTypes: null,
                salaryTypeDesc: null,
                agentList: null,
                studioList: null,
            },

            createForm: {
                name: null,
                sex: null,
                age: null,
                wechat: null,
                dy: null,

                talent: null,
                talentType: null,

                jobType: null,
                takeLongTime: null,
                dailyWorkHour: null,
                workTimeStart: null,
                workTimeEnd: null,
                scType: null,
                canJoinGuild: null,
                haveExp: null,
                student: null,

                expectStudioType: null,

                expectStartDate: null,

                remark: null,

                agentId: null,

                studioId: null,

                workTimeArr: [],
            },

        };
    },

    methods: {
        loadCreateInfo() {
            this.axios.get("/anchor/createInfo", {}).then((resp) => {
                if (resp.data.success) {
                    this.createInfo = resp.data.data;
                } else {
                    this.$message.error(resp.data.msg);
                }
            });
        },

        create() {
            if (this.createForm.scType == '') {
                this.createForm.scType = null;
            }
            this.axios.post("/anchor/create", this.createForm).then((resp) => {
                if (resp.data.success) {
                    this.$message.success("保存成功");
                } else {
                    this.$message.error(resp.data.msg);
                }
            });
        }
    },

    created() {
        this.loadCreateInfo();
    },
};
</script>

<style scoped></style>